import React, {useEffect} from 'react'
import logo from '../images/logo.png';
import Footers from '../Components/Footers';
import { Link } from 'react-router-dom';

const Privacy = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  return (
    <React.Fragment>
     
    
    <div>
        <div className=' h-auto mb-36'>
            {/* banner is done */}
                <div className='bg-white h-fit w-100 flex justify-around  flex-col'>
                    <div className='flex flex-row justify-start lg:w-6/12 h-fit  lg:text-5xl text-2xl font-bold lg:mx-auto mt-24'>
                    <Link to="/"><img src={logo} className='w-28' /></Link>
                    <ul className='text-sm flex flex-row text-red-500 font-mono'>
                    <Link to ="/terms"><li className='mx-3'>Terms</li></Link>
                    <Link to ="/privacy-policy"><li className='mx-3'>Privacy</li></Link>
                    <Link to ="/contact-us"><li className='mx-3'>Get in touch</li></Link>
                    </ul>
                    </div>
                    {/* <BannerHome/> */}
                    <div className='flex flex-wrap lg:w-6/12 h-fit  lg:text-5xl text-2xl font-bold mt-20 mx-auto'>
                    Privacy Policy
                    </div>
                </div>

           <div className='flex font-mono flex-col flex-wrap md:w-6/12  p-10 pt-0  mx-auto'>
            <div className='text-2xl font-bold mt-10 '>Overview</div>
           <span>Your privacy is important to us. It is UIUXjobshub's policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, https://UIUXjobshub.com, and other sites we own and operate.
            This policy is effective as of 26 November 2018 and was last updated on 7 September 2021.</span>
           </div>

            

           <div className='flex font-mono flex-col flex-wrap md:w-6/12  p-10 pt-0  mx-auto'>
            <div className='text-2xl font-bold mt-10'>Children’s Privacy</div>
           <span>We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13.</span>
           </div>

           <div className='flex font-mono flex-col flex-wrap md:w-6/12  p-10 pt-0  mx-auto'>
            <div className='text-2xl font-bold mt-10'>International Transfers of Personal Information</div>
           <span>The personal information we collect is stored and/or processed where we or our partners, affiliates, and third-party providers maintain facilities. Please be aware that the locations to which we store, process, or transfer your personal information may not have the same data protection laws as the country in which you initially provided the information. If we transfer your personal information to third parties in other countries: (i) we will perform those transfers in accordance with the requirements of applicable law; and (ii) we will protect the transferred personal information in accordance with this privacy policy.</span>
           </div>

           <div className='flex font-mono flex-col flex-wrap md:w-6/12  p-10 pt-0  mx-auto'>
            <div className='text-2xl font-bold mt-10'>Your Rights and Controlling Your Personal Information</div>
           <span>You always retain the right to withhold personal information from us, with the understanding that your experience of our website may be affected. We will not discriminate against you for exercising any of your rights over your personal information. If you do provide us with personal information you understand that we will collect, hold, use and disclose it in accordance with this privacy policy. You retain the right to request details of any personal information we hold about you.

                If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.

                If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time. We will provide you with the ability to unsubscribe from our email-database or opt out of communications. Please be aware we may need to request specific information from you to help us confirm your identity.

                If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date.

                If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.</span>
           </div>

           <div className='flex font-mono flex-col flex-wrap md:w-6/12  p-10 pt-0  mx-auto'>
            <div className='text-2xl font-bold mt-10'>Use of Cookies</div>
           <span>We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified.</span>
           </div>

           <div className='flex font-mono flex-col flex-wrap md:w-6/12  p-10 pt-0  mx-auto'>
            <div className='text-2xl font-bold mt-10'>Limits of Our Policy</div>
           <span>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.</span>
           </div>


           <div className='flex font-mono flex-col flex-wrap md:w-6/12  p-10 pt-0  mx-auto'>
            <div className='text-2xl font-bold mt-10'>Changes to This Policy</div>
           <span>At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing this privacy policy.
            If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.</span>
           </div>

           <div className='flex  font-mono flex-col flex-wrap md:w-6/12  p-10 pt-0  mx-auto'>
            <div className='text-2xl font-bold mt-10'>Contact Us</div>
           <span>For any questions or concerns regarding about the Terms of Service, you may contact us using the following details: hello@UIUXjobshub.com</span>
           </div>

        </div>
        <Footers/>
    </div>
    </React.Fragment>
  )
}

export default Privacy;